<script setup lang="ts">
import type { Graph, GraphRun, RunGroup } from '@respell/database';
import type { Json, Variable } from '@respell/utils';
import { typeMap } from '@respell/utils';
import Papa from 'papaparse';
import CreateBulkGroupModal from '~/components/modals/CreateBulkGroupModal.vue';
import ImportCSVModal from '~/components/modals/ImportCSVModal.vue';
import { bulkRunLimitReached } from '~/util/notifications';
import { createOptionSchema } from '~/util/validation';

const spellId = useRouteParams('spellId');
const groupId = useRouteParams('groupId');

const toast = useToast();
const modal = useModal();
const spellStore = useSpellsStore();

const { stopStreaming } = useStreaming();

const {
  versions,
  groupRuns: runs,
  groupRunCount: runCount,
  bulkGroups: groups,
  isRunning,
} = storeToRefs(spellStore);

const rerunType = ref<'unfinished' | 'all' | null>(null);
const page = ref(1);
const pageSize = ref(10);
const activeRunId = useRouteParams('runId');

watch(groupId, () => {
  page.value = 1;
  pageSize.value = 10;
});

const { pending: isFetchingGroups } = await useAsyncData(
  `bulkGroups/${spellId.value}`,
  () => spellStore.loadBulkGroups(),
);

const { pending: isFetchingRuns } = await useAsyncData(
  `groupRuns/${groupId.value}`,
  () =>
    spellStore.loadGroupRuns(groupId.value as string, {
      page: page.value,
      pageSize: pageSize.value,
    }),
  {
    immediate: !!groupId.value,
    watch: [groupId, page, pageSize],
  },
);

const activeGroup = computed(() => {
  return groups.value?.find((group: RunGroup) => group.id === groupId.value);
});

const graph = computed(() => {
  if (!activeGroup.value) return null;
  return versions.value?.find(
    (version: Graph) => version.id === activeGroup.value?.graphId,
  );
});

const groupColumns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'completed',
    label: 'Completed',
  },
];

const groupRows = computed(() => {
  return groups.value?.map((group: RunGroup) => ({
    id: group.id,
    name: group.name,
    status: group.state,
    completed: [
      group.runs.filter((run: GraphRun) => !run.stepErrorOccured).length,
      group.runs.length,
    ],
  }));
});

const runColumns = computed(() => {
  return Object.values(graph.value?.inputs || {})
    .filter((input): input is Variable => input !== undefined)
    .map((input: Variable) => ({
      ...input,
      slot: 'input',
      class: 'bg-primary-100',
    }))
    .concat(
      Object.values(graph.value?.outputs || {})
        .filter((output): output is Variable => output !== undefined)
        .map((output: Variable) => ({
          ...output,
          slot: 'output',
          class: 'bg-green-50',
        })),
    );
});

const runRows = computed(() => {
  return runs.value?.map((run: GraphRun) => ({
    id: run.id,
    state: run.state,
    hasError: run.stepErrorOccured,
    ...run.inputs,
    ...run.outputs,
    errors: validateInputs(run.inputs),
    class: activeRunId.value === run.id ? 'bg-gray-100' : '',
  }));
});

const totalRows = computed(() =>
  activeGroup.value ? runCount.value : groups.value?.length,
);

const runOptions = computed(() => {
  //TODO: Fix logic once top level run erro
  const unfinished = runRows.value?.filter(
    (row) => row.errors || row.hasError,
  ).length;
  return [
    [
      {
        label: `Run unfinished (${unfinished})`,
        disabled: !unfinished,
        click: () => handleBulkRun('unfinished'),
      },
    ],
    [
      {
        label: `Run all again`,
        click: () => handleBulkRun('all'),
      },
    ],
  ];
});

const importOptions = [
  [
    {
      label: 'Append new rows',
      click: () => modal.open(ImportCSVModal),
    },
  ],
  [
    {
      label: 'Overwrite existing rows',
      click: () => modal.open(ImportCSVModal, { overwrite: true }),
    },
  ],
];

async function selectGroup(group: RunGroup) {
  await navigateTo({
    name: 'spell.bulk',
    params: { spellId: spellId.value, groupId: group.id },
    replace: true,
  });
}

async function selectRun(run: GraphRun) {
  if (run.state === 'success') {
    await navigateTo({
      name: 'spell.bulk',
      params: { runId: run.id, spellId: spellId.value, groupId: groupId.value },
      replace: true,
    });
  } else {
    toast.add({
      title: "Run this bulk group to see this run's details",
      id: 'modal-success',
    });
  }
}

async function handleBulkRun(type?: 'unfinished' | 'all') {
  rerunType.value = type;

  try {
    await spellStore.handleBulkRun(groupId.value as string, type);
  } catch (error) {
    if (error.statusCode === 429) {
      toast.add(bulkRunLimitReached);
    } else {
      console.error('An error occurred:', error);
    }
  }
}

function downloadResults() {
  const headers = runColumns.value?.map((col) => col.key);
  const rows = runRows.value?.map((row) => {
    const rowData: Record<string, any> = {};
    headers?.forEach((header) => {
      rowData[header] = row[header];
    });
    return rowData;
  });

  const csv = Papa.unparse({
    fields: headers,
    data: rows,
  });

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'results.csv');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function validateInputs(inputs: Json) {
  if (!graph.value?.inputs) return null;
  const schema = createOptionSchema(graph.value?.inputs);
  const result = schema.safeParse(inputs);
  return result.success ? null : result.error?.flatten().fieldErrors;
}

onBeforeRouteLeave(() => {
  stopStreaming();
});
</script>
<template>
  <NuxtLayout name="spell-tab" :wide="true">
    <!-- HEADER -->
    <span class="flex justify-start w-full">
      <UButton
        v-if="activeGroup"
        icon="i-ph-arrow-left"
        size="md"
        color="gray"
        variant="ghost"
        :to="{ name: 'spell.bulk', params: { spellId }, replace: true }"
      />
      <p class="title">
        {{ activeGroup?.name ?? 'Bulk Runs' }}
      </p>
      <UButton
        v-if="!activeGroup"
        label="Add new"
        icon="i-ph-plus"
        class="ml-auto"
        @click="() => modal.open(CreateBulkGroupModal)"
      />
    </span>
    <div
      class="border-container mt-m p-m pb-l bg-white w-full flex flex-col rounded-2xl gap-4 items-start"
    >
      <!-- ACTIVE GROUP PANE -->
      <div v-if="activeGroup" class="contents">
        <!-- BUTTON ROW -->
        <div class="w-full flex gap-3">
          <UDropdown :items="totalRows > 0 ? importOptions : undefined">
            <UButton
              color="white"
              label="Import CSV"
              icon="i-ph-cloud-arrow-up"
              @click="totalRows > 0 ? undefined : modal.open(ImportCSVModal)"
            />
          </UDropdown>
          <UButton
            v-if="activeGroup.state === 'success'"
            color="white"
            label="Download CSV"
            icon="i-ph-file-arrow-down"
            @click="downloadResults"
          />
          <UTooltip
            :prevent="!runRows.some((row) => row.errors)"
            text="You must resolve all input errors to run this group"
            class="ml-auto"
          >
            <UDropdown
              :items="activeGroup.state === 'success' ? runOptions : undefined"
            >
              <UButton
                :loading="isRunning"
                label="Run all"
                :disabled="runRows.some((row) => row.errors)"
                icon="i-ph-fast-forward-fill"
                @click="
                  activeGroup.state === 'success' ? undefined : handleBulkRun()
                "
              />
            </UDropdown>
          </UTooltip>
        </div>
        <!-- RUN TABLE -->
        <UTable
          v-if="totalRows"
          :loading="isFetchingRuns"
          :rows="runRows"
          :columns="runColumns"
          class="w-full"
          :empty-state="null"
          style="scrollbar-width: auto"
          :ui="{ tr: { base: 'divide-x' }, td: { base: 'max-w-96' } }"
          @select="selectRun"
        >
          <!-- COLUMN HEADERS -->
          <template
            v-for="col in runColumns"
            :key="col.key"
            #[`${col.key}-header`]="{ column }"
          >
            <span class="flex gap-2 justify-start">
              <UIcon
                :name="typeMap[column.type].icon"
                class="w-5 h-5"
                :class="
                  column.slot === 'input'
                    ? 'text-primary-500'
                    : 'text-green-500'
                "
              />
              <p
                class="body truncate"
                :class="
                  column.slot === 'input'
                    ? 'text-primary-500'
                    : 'text-green-500'
                "
              >
                {{ column.name }}
              </p>
            </span>
          </template>
          <template
            v-for="col in runColumns"
            :key="col.key"
            #[`${col.key}-data`]="{ column, row }"
          >
            <!-- RENDER OUTPUT -->
            <div v-if="column.slot === 'output'" class="contents">
              <div v-if="row.state === 'pending'" class="contents">
                <span v-if="isRunning" class="flex gap-1">
                  <UIcon
                    name="i-ph-arrow-clockwise"
                    class="text-xl animate-spin text-primary-500"
                  />
                  <p class="body text-primary-500">Running...</p>
                </span>
                <p v-else class="body dimmed">
                  This output will be generated by the bulk run
                </p>
              </div>
              <span v-else-if="row[column.key]">
                <UBadge
                  v-if="column.type?.includes('file')"
                  color="primary"
                  variant="subtle"
                  size="lg"
                >
                  File: {{ row[column.key].split(':')[3] }}
                </UBadge>
                <p v-else class="body truncate">
                  {{ row[column.key] }}
                </p>
              </span>
              <span
                v-else
                class="flex gap-1 justify-start body truncate text-orange-400"
              >
                <UIcon
                  name="i-ph-warning-diamond-fill"
                  class="text-xl text-orange-400"
                />
                This output failed to resolve. Select to view details.
              </span>
            </div>

            <!-- RENDER INPUT -->
            <UTooltip
              v-else
              :prevent="!row.errors?.[column.key]"
              class="body truncate flex gap-1 justify-start"
              :text="row.errors?.[column.key]?.join('; ')"
              :class="{
                'text-red-500': !!row.errors?.[column.key],
              }"
            >
              <UIcon
                v-if="row.errors?.[column.key]"
                name="i-ph-warning-diamond-fill"
                class="text-xl text-red-500"
              />
              {{ row[column.key] }}
            </UTooltip>
          </template>
        </UTable>
      </div>
      <!-- GROUP TABLE -->
      <UTable
        v-else-if="totalRows"
        :loading="isFetchingGroups"
        :rows="groupRows.slice((page - 1) * pageSize, page * pageSize)"
        :columns="groupColumns"
        :empty-state="null"
        class="w-full"
        style="scrollbar-width: auto"
        :ui="{
          wrapper: 'border-0',
          base: 'border-separate border-spacing-y-2 divide-y-0',
          divide: 'divide-y-0',
          th: {
            font: 'font-normal',
            padding: 'py-0',
          },
          td: {
            base: 'border-y first:border-l first:rounded-l-2xl last:border-r last:rounded-r-2xl',
          },
        }"
        @select="selectGroup"
      >
        <template #name-data="{ row }">
          <p class="body font-bold truncate">{{ row.name }}</p>
        </template>
        <template #status-data="{ row }">
          <span v-if="row.status === 'inProgress'" class="flex gap-1">
            <UIcon
              name="i-ph-arrow-clockwise"
              class="text-xl animate-spin text-primary-500"
            />
            <p class="body text-primary-500">Running...</p>
          </span>
          <UBadge
            v-else
            :color="row.status === 'success' ? 'green' : 'primary'"
            :label="row.status"
            :variant="row.status === 'success' ? 'solid' : 'subtle'"
            :ui="{ rounded: 'rounded-2xl', font: 'capitalize' }"
          />
        </template>
        <template #completed-data="{ row }">
          <p class="body dimmed">
            <span class="font-bold text-black">{{ row.completed[0] }}</span> /
            {{ row.completed[1] }}
          </p>
        </template>
      </UTable>
      <!-- EMPTY STATE -->
      <div
        v-if="!totalRows"
        class="flex flex-col w-full py-6 gap-2 items-center"
      >
        <UIcon name="i-ph-smiley-melting" class="text-gray-400 text-3xl" />
        <p class="subtitle">
          You haven't
          {{
            activeGroup ? 'queued any runs yet' : 'created any bulk groups yet'
          }}
        </p>
        <p class="body dimmed">
          {{
            activeGroup
              ? 'Import a CSV to get started'
              : 'Create one to get started'
          }}
        </p>
        <UButton
          :label="activeGroup ? 'Import CSV' : 'Add new'"
          :icon="activeGroup ? 'i-ph-cloud-arrow-up' : 'i-ph-plus'"
          color="white"
          @click="
            () =>
              activeGroup
                ? modal.open(ImportCSVModal)
                : modal.open(CreateBulkGroupModal)
          "
        />
      </div>
      <!-- PAGINATION -->
      <div v-if="totalRows > pageSize" class="flex justify-center w-full">
        <UPagination v-model="page" :page-count="pageSize" :total="totalRows" />
      </div>
    </div>
  </NuxtLayout>
</template>
